import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 6-6-6-6 to a 6 rep max`}</p>
    <p>{`Band Face Pulls 12-12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`25-Calorie Assault Bike`}</p>
    <p>{`20-Pullups`}</p>
    <p>{`15-S2OH (115/75)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be having a “yard sale” at The Ville today, selling extra
equipment we have from East and The Ville.  If you need something for
your garage gym stop by and see what we have available. 9:00-12:00.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder: Our new Ville schedule takes effect this week!  Check it
out on the Schedule page.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      